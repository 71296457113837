.program-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #f4f4f4;
  min-height: 100vh;
  text-align: center;
}

.program-heading {
  font-size: 1.6em;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #A092C9;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 1s ease-out forwards;
  text-align: start;
}

.program-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem; /* Increased gap for better separation */
  width: 100%;
  max-width: 1200px;
}

.program-item {
  background-color: #fff;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.program-item.fade-in {
  opacity: 1;
  transform: translateY(0);
}

/* Icon Styling */
.program-icon {
  font-size: 4.5rem; /* Increased size */
  margin-bottom: 0.5rem; /* Reduced margin between icon and text */
  transition: color 0.3s ease; /* Smooth color transition */
}

.program-item:hover .program-icon {
  color: white;
}

/* Hover Effect */
.program-item:hover {
  background-color: #A092C9;
  color: #fff;
  transform: translateY(-10px);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

h3 {
  font-size: 1.4rem;
  margin-bottom: 0.5rem; /* Reduced margin for closer alignment */
}

p {
  font-size: 1rem;
  color: #555;
}

/* Program Icon Colors */
.program-icon.green {
  color: #28a745; /* Green */
}

.program-icon.purple {
  color: #A092C9; /* Purple */
}

.program-icon.orange {
  color: #ff8c00; /* Orange */
}

.program-icon.blue {
  color: #007bff; /* Blue */
}

.program-icon.yellow {
  color: #f0ad4e; /* Yellow */
}

/* Media Query for Responsiveness */
@media (max-width: 768px) {
  .program-box {
    grid-template-columns: 1fr;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
