.footer {
    background-image: url('https://res.cloudinary.com/ddkyeuhk8/image/upload/v1731415764/qbq1sv3tuyva3wb2omzn.svg'); /* Update with the correct path */
    background-size: cover;
    background-position: center;
    padding: 20px 0;
    display: flex;
    justify-content: end;
    align-items: end;
    height: 220px;
    width: 100%;
  }
  
  .footer-content {
    display: flex;
   padding-right: 30px;
    gap: 20px;
  }
  
  .social-icon {
    color: #fff;
    font-size: 24px;
    transition: color 0.3s;
  }
  
  .social-icon:hover {
    color: #0c038d; /* Change to your preferred hover color */
  }
  