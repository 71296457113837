* {
    transition: 0.3s;
  }
  
 
  
  .container {
    max-width: 80%;
    margin-left: 10%;
  }
  

  .item {
    padding: 1rem;
    text-align: start;
    border-radius: 5px;
    background: linear-gradient(to right, #6836C529 0% 25%, #B636C529 80%);
    color: #493B60;
    margin-bottom: 1rem;
    cursor: pointer;
    overflow: hidden;
    max-height: 65px;
    transition: all 0.3s ease;
  }
  
  .item.open {
    max-height: 1000px;
    background-size: 200%;
    
  }
  
  /* Title Styling */
  .title {
    color: #2B2732;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Text Styling */
  .text {
    max-height: 0;
    overflow: hidden;
    padding-top: 0;
    transition: all 0.3s ease;
  }
  
  .text.show {
    max-height: 1000px;
    padding-top: 1rem;
    color: #555;
  }
  
  /* Icon Styling */
  .icon-wrapper {
    display: inline-flex;
    transition: transform 0.3s ease;
  }
  
  .icon-wrapper.rotate {
    transform: rotate(180deg);
  }
  
  .icon {
    font-size: 1.25rem;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .text {
      font-size: 0.9rem;
    }
  
    .title .text-lg {
      font-size: 1rem;
    }
  
    .icon {
      font-size: 1rem;
    }
    .container {
        max-width: 90%;
        margin-left: 5%;
      }
  }
  