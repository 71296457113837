/* ProfilePage.css */

/* Overall page container */
.profile-page {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .profile-image-container {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
  
  .profile-image {
    width: 300px; 
    height: auto;
  
    object-fit: cover;
  }
  
  /* Content styling for desktop */
  .profile-content {
    margin-left: -12px; 
    font-size: 1.2em;
    line-height: 1.5;
    color: #333;
    font-weight: 500;
    max-width: 600px;
    background-color: #A092C9; 
  height: 136px;
  margin-top: 55px;
  border-top-right-radius: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  }
  .profile-content p{
    font-size: 1.3em;
    line-height: 122.5%;
    padding: 13px;
    text-align: start;
    color: white;
    
  }
.coachrajan{
  font-size: 1.21em;
  font-weight: 600;
  color: #032f5e;
  text-shadow: 
      -1px -1px 0 #ffffff, 
       1px -1px 0 #ffffff, 
      -1px  1px 0 #ffffff, 
       1px  1px 0 #ffffff; 
   
}
  @media screen and (max-width: 768px) {
    .profile-page {
      flex-direction: column;
      text-align: center;
    }
  
    .profile-image-container {
      margin-bottom: -5px;
    }
    .profile-image {
        width: 90%; 
        height: auto;
      
        object-fit: cover;
      }
    .profile-content {
        width: 63%;
        background-color: #A092C9;
        padding: 10px;
        border-radius: 10px;
        margin-top: -5px;
        color: white;
        font-size: 4.3vw;
        height: 533px;
        margin-top: -120px;
        z-index: -1;
    }
  }
  @media screen and (max-width: 548px) {
.profile-content{
  width: 92%;
        background-color: #A092C9;
        padding: -5px;
        border-radius: 10px;
       
        color: white;
        font-size: 14px;
        height: 146px;
        margin-top: -4px;
        z-index: -1;
}
  }



  /* Achievements.css */

.achievements-container {
  padding: 20px;
  background-color: #A092C9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 77%;
  margin-left: 5%;
  margin-bottom: 20px;
  color: white;
  text-align: start;
  }
  
  .achievements-container h2 {
    font-size: 1.8em;
    color: #032f5e;
    display: flex;
    align-items: center;
  }
  
  .achievements-container p, .achievements-container li {
    font-size: 1em;
    color: #ffffff;
    display: flex;
    align-items: center;
    margin: 5px 0;
  }
  
  .achievements-container ul {
    list-style: none;
    padding-left: 0;
  }
  
  .achievements-container svg {
    margin-right: 8px;
    color: #ff4500; /* Change this color as needed */
  }
  