.admin-page {
    padding: 2rem;
    max-width: 100%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.admin-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
    color: #333;
}

.admin-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    gap: 1rem;
}

.search-input,
.sort-select {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.enquiries-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 1rem;
    overflow-x: auto;
    display: block;
}

.enquiries-table th,
.enquiries-table td {
    padding: 0.75rem;
    border: 1px solid #ddd;
    text-align: center;
}

.enquiries-table th {
    background-color: #f5f5f5;
    font-weight: bold;
}

.delete-button {
    background-color: #e74c3c;
    color: white;
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.delete-button:hover {
    background-color: #c0392b;
}

@media (max-width: 768px) {
    .admin-actions {
        flex-direction: column;
        align-items: flex-start;
    }

    .search-input,
    .sort-select {
        width: 100%;
    }

    .enquiries-table {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .admin-title {
        font-size: 1.5rem;
    }

    .enquiries-table th,
    .enquiries-table td {
        padding: 0.5rem;
        font-size: 0.8rem;
    }
}
.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .login-page form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .login-page input,
  .login-page button {
    padding: 10px;
    margin: 5px;
    font-size: 16px;
  }
  
  .login-page button {
    cursor: pointer;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .login-page button:hover {
    background-color: #555;
  }
  