.how-it-works{
  background-color: #A092C9;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.how-it-works-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
 
}
.how-it-worksh1{
  color: #032f5e;
}
.steps-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  width: 80%;
  max-width: 1200px; /* Ensure the content is centered and not too wide */
}

/* Each step container */
.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 23%;
  opacity: 1; /* AOS will control visibility */
  position: relative;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.step-number {
  background-color: #032f5e;
  color: white;
  font-size: 24px;
  padding: 5px;
  border-radius: 50%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 50px;
  width: 50px;
}

.step-content {
  text-align: center;
}

.step-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #032f5e;
}

.step-description {
  font-size: 14px;
  color: #ffffff;
}

/* Vertical line between steps */
.step-line-vertical {
  width: 225px;
  height: 2px;
  background-color: #032f5e;
  position: absolute;
  top: 34px;
  right: -122px;
}

/* Horizontal line connecting all steps */
.steps-wrapper::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #032f5e;
  top: 50px;
  z-index: -1;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .how-it-works{
    height: 900px;
  }
  .how-it-works-container {
    padding: 20px 0;
    max-height: none; /* Allow for scrolling on mobile */
  }

  .steps-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .step-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 50px;
    opacity: 1; /* Skip animations for simplicity on mobile */
  }

  .step-number {
    flex-shrink: 0;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .step-line-vertical {
    width: 2px;
    height: 100%;
    position: absolute;
    left: 28px;
    top: 57px;
  }

  .step-content {
    text-align: left;
    padding-left: 20px;
  }

  .steps-wrapper::before {
    content: none; /* Hide horizontal line on mobile */
  }
}
