.facts-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    margin: 80px 0;
}

.countdown-fact {
    width: 310px;
    text-align: center;
    background-color: #A092C9;
    border-radius: 8px;
    padding: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.countdown-fact:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.countdown-card {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fact-number {
    font-size: 2.5rem;
    color: #032f5e; /* Main numbering color */
    margin: 0;
}

.fact-title {
    font-size: 1.1rem;
    color: white; /* Paragraph color */
    margin-top: 10px;
    line-height: 1.4;
}
