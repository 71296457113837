

.button-page {
  text-align: center;
  padding: 20px;
}

.open-modal-button {
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.open-modal-button:hover {
  background-color: #0056b3;
}

.modal {
  position: relative;
  max-width: 500px;
  margin: 10% auto;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  max-height: 80vh;
  overflow-y: auto;

}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #888;
  transition: color 0.3s ease;
}

.close-icon:hover {
  color: #333;
}

.modal-title {
  text-align: center;
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
}

.enquiry-form label {
  display: block;
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.enquiry-form input,
.enquiry-form select,
.enquiry-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.enquiry-form input:focus,
.enquiry-form select:focus,
.enquiry-form textarea:focus {
  border-color: #007bff;
  outline: none;
}

textarea {
  resize: vertical;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.submit-button,
.close-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.submit-button {
  background-color: #28a745;
  color: white;
}

.submit-button:hover {
  background-color: #218838;
}

.close-button {
  background-color: #dc3545;
  color: white;
}

.close-button:hover {
  background-color: #c82333;
}
