/* Container and Heading */
.app-container {
    text-align: center;
    padding: 20px;
  }
  
  .heading {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  /* Image Container */
  .imageContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: nowrap;
  }
  
  /* Image Wrapper */
  .imageWrapper {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Image Style */
  .image {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  
  .image:hover {
    transform: scale(1.05);
  }
  
  /* Name Style */
  .name {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 700;
  }
  .apprih{
    font-size: 23px;
  }
  /* Responsive Styles */
  @media (max-width: 600px) {
    .imageContainer {
      flex-direction: row;
      align-items: center;
    }
  
    .image {
      width: 95%;
      height: auto;
    }
  
    .name {
      margin-top: 10px;
      font-size: 15px;
      font-weight: 700;
    }
   
  }
  