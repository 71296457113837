.logoGridSection .container {
  width: 100%;
  padding: 20px 0;
}

.marquee {
  overflow: hidden;
  white-space: nowrap;
  display: flex;
}

.marquee-content {
  display: flex;
  animation: marquee 10s linear infinite;
}

.grid-logo {
  width: auto;
  max-width: 150px;
  height: 50px;
  margin-right: 20px;
  object-fit: contain;
}

/* Marquee animation for looping effect */
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.marquee-content:hover {
  animation-play-state: paused;
}

@media (max-width: 768px) {
  .marquee-content {
    animation-duration: 15s;
  }
}
