/* Importing Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
  }
.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding: 0 0rem;
    background: url('https://res.cloudinary.com/ddkyeuhk8/image/upload/v1731387223/v8tsu833l8aqypmoyof9.png') no-repeat center center / cover;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    overflow: hidden;  
}

.hero-content {
   width: 80%;
    text-align: center;
    color: #fff;
    backdrop-filter: blur(5px);
    opacity: 0;
    transform: translateY(30px);
    animation: scrollIn 1s ease-out forwards; 
}

.hero-heading {
    font-size: 2.5rem;
    font-weight: 500;
    margin-bottom: 0;
   
   
}


.hero-H1 {
    position: relative;
    font-family: "Poppins", sans-serif;
    color: #032f5e;
    background-image: radial-gradient(#f6d8d5);
    font-size: 8vw;
    font-weight: 900;
    width: 95%;
    margin: 0 auto;
    height: auto;
    line-height: 1.1;
    opacity: 0;
    transform: translateY(20px);
    animation: scrollIn 1s ease-out forwards 0.5s; /* Apply the scroll-in animation with delay */
}

.hero-H1::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0em;
    color: #f5dd05;
    z-index: -1;
    transition: 0.2s;
    width: 100%;
    height: 100%;
}

.hero-H1:hover::before {
    top: 0.04em;
    left: 0.04em;
}

.hero-H1::after {
    content: attr(data-text);
    position: absolute;
    color: transparent;
    top: 0;
    left: 0em;
    background-image: radial-gradient(rgba(229, 34, 236, 0.5) 0.0125em, transparent 0.0125em);
    background-size: 8px 8px;
    -webkit-background-clip: text;
    background-clip: text;
    
    transition: 0.2s;
    width: 100%;
    height: 100%;
}

.hero-H1:hover::after {
    top: -0.02em;
    left: -0.02em;
}

.hero-paragraph {
    font-size: 1rem;
    line-height: 1.6;
    margin: 1rem 0;
    color: #f1f1f1;
    opacity: 0;
    transform: translateY(20px);
    animation: scrollIn 1s ease-out forwards 0.7s; 
    padding: 0 20%;
    font-weight: 600;
    
    
}
.hero-paragraph span {
    font-size: 1.3rem;
    font-weight: 700;
    text-shadow: 
        -1px -1px 0 #032f5e, 
         1px -1px 0 #032f5e,
        -1px  1px 0 #032f5e, 
         1px  1px 0 #032f5e; 
}

.hero-button {
    display: inline-block;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff;
    background: #032f5e;
    border: none;
    border-radius: 25px;
    transition: all 0.3s ease;
    cursor: pointer;
    text-transform: uppercase;
    
    opacity: 0;
    transform: translateY(20px);
    overflow: hidden;
    animation: scrollIn 1s ease-out forwards 0.9s, widthPulse 2s ease-in-out infinite, pulseGlow 3s ease-in-out infinite;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

@keyframes pulseGlow {
    0%, 100% {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        background-color: #032f5e;
    }
    50% {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
        background-color: #044ca0;
    }
}

/* Width pulsating animation */
@keyframes widthPulse {
    0%, 100% {
        transform: translateY(2px) scaleX(1);
    }
    50% {
        transform: translateY(8px) scaleX(1.1); /* Slight width increase */
    }
}


.hero-button:hover {
    background: #A092C9;
    color: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    animation: scrollIn 1s ease-out forwards; 
}


@keyframes scrollIn {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .hero {
        background-image: url('https://res.cloudinary.com/ddkyeuhk8/image/upload/v1731386897/niehbvpaww0hcqljdegr.png');
        background-size: cover;
        padding: 0 0;
    }
    .hero-content {
        width: 99%;
         text-align: center;
         color: #fff;
         backdrop-filter: blur(5px);
         opacity: 0;
         transform: translateY(30px);
         animation: scrollIn 1s ease-out forwards; 
     }

    .hero-H1 {
        font-size: 11vw;
        width: 100%;
    }
    .hero-heading {
        font-size: 5vw;
        font-weight: 500;
        margin-bottom: 10px;
        width: 100%;
        margin-top: -10px;
    }
    .hero-paragraph {
        font-size: 1rem;
        line-height: 1.6;
        margin: 1rem 0;
        color: #f1f1f1;
        opacity: 0;
        transform: translateY(20px);
        animation: scrollIn 1s ease-out forwards 0.7s; 
        padding: 0 2%;
    }
}
