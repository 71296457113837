.carousel-container-main {
  width: 100%;
  background-color: #A092C9;
}

.carousel-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
  height: auto;
}

.cch1 {
  color: #032f5e;
}

.carousel-slide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.carousel-image {
  width: 26vw;
  height: 26vw;
  object-fit: cover;
  transition: transform 0.5s ease-in-out, filter 0.5s ease-in-out;
  filter: grayscale(30%);
  border: 4px solid transparent;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 8px;
  margin: 8px;
}

.slick-center .carousel-image {
  transform: scale(1.1);
  filter: grayscale(0);
  border: 4px solid #032f5e;
  z-index: 1;
}

@media (max-width: 768px) {
  .carousel-image {
    width: 75vw;
    height: 75vw;
  }
}

.slick-prev, .slick-next {
  background-color: #302f2f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 2;
}

.slick-next {
  right: -17px;
}

.slick-prev:hover, .slick-next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.slick-prev:before, .slick-next:before {
  font-size: 20px;
  color: white;
}

.slick-dots {
  bottom: -30px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #007bff;
}

.slick-dots li.slick-active button:before {
  color: #000;
}

.video-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.video-container {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  height: 600px; /* Set the video container height to 600px */
}

.video-container:hover {
  transform: scale(1.02);
}

.video {
  width: 100%;
  height: 100%; /* Make the iframe fill the container */
  border-radius: 8px 8px 0 0;
}

.video-title {
  text-align: center;
  padding: 10px;
  font-size: 1rem;
  color: #333;
}
